<template>
  <div>
    <a-row class="d-flex align-items-center mb-4 justify-items-center sticky-top"
      style="margin: -30px; padding: 22px 30px; top: 67px; background: #fff">
      <a-col :span="18">
        <div class="d-flex flex-column">
          <a-breadcrumb>
            <a-breadcrumb-item><a style="color: #1890FF" @click="redirectToMain">Main</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">Application
                List</a></a-breadcrumb-item>
          </a-breadcrumb>
          <h3 class="mb-0 mt-2" style="font-size: 20px">{{ viewScreenTitle }}</h3>
        </div>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end">
        <div v-if="isEditMode">
          <a-popconfirm title="Sure to delete?" placement="bottom" @confirm.stop="deleteViewscreen">
            <a-button :loading="loading" type="danger"> Delete </a-button>
          </a-popconfirm>
          <a-button :loading="loading" type="primary" class="ml-4" @click="save">
            Save
          </a-button>
        </div>
        <div v-else class="pt-4">
          <a-button class="ml-4" @click="clearForm">
            Clear
          </a-button>
          <a-button :loading="loading" type="primary" class="ml-4" @click="save">
            Save
          </a-button>
        </div>
      </a-col>
    </a-row>
    <div style="padding: 24px; background-color: #fff">
      <a-form-model :rules="rules" :model="form" ref="form">
        <div class="d-flex flex-column viewscreen-form">
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="Name viewscreen" prop="viewscreenName" :colon="false">
                <a-input v-model="form.viewscreenName" placeholder="Name" size="large" :disabled="loading" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item prop="viewscreenSegment" :colon="false">
                <template #label>
                  <span class="mr-4">Segments</span>
                  <a @click.stop="openAddSegmentsModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+
                    Add</a>
                </template>
                <div class="d-flex align-items-center">
                  <a-select :disabled="loading" v-model="form.viewscreenSegment" size="large">
                    <template #placeholder><span style="color: #a1a1c2">Choose segment</span></template>
                    <a-select-option v-for="segment in segments" :key="segment.id" :value="segment.id">
                      <span style="display: block;">{{ segment.name }}</span>
                    </a-select-option>
                  </a-select>
                  <a v-if="!!form.viewscreenSegment && isSegmentEditable" @click.stop="openEditSegmentModal"
                    class="ml-3" style="color: rgb(0, 123, 255) !important; font-weight: 400">Edit</a>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="viewscreen-form__content">
            <a-form-model-item prop="viewscreenContent" :colon="false">
              <template #label>
                <span>Content</span>
              </template>
              <a-radio-group name="radioGroup" v-model="form.viewscreenContent" :default-value="form.viewscreenContent"
                @change="checkContentType" :disabled="isEditMode">
                <a-radio value="empty"> Empty blank </a-radio>
                <a-radio value="inherit"> Inherit from {{ contentViewscreen }} </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </div>
          <div class="d-flex flex-row align-items-start" style="gap: 1rem">
            <a-form-model-item label="Start date" prop="startDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.startDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
            <a-form-model-item label="End date" prop="endDate" :colon="false" class="d-inline-block pr-1">
              <a-date-picker v-model="form.endDate" show-time format="YYYY-MM-DD HH:mm:ss" type="date" size="large"
                placeholder="Select date" :disabled="loading" />
            </a-form-model-item>
          </div>
          <a-form-model-item prop="viewscreenApps" required :colon="false" class="d-block">
            <template #label>
              <span class="mr-4">Apps</span>
              <a @click.stop="openAddAppModal" style="color: rgb(0, 123, 255) !important; font-weight: 400">+ Add</a>
            </template>
            <div v-if="form.viewscreenApps">
              <a-tag style="
                    margin-right: 15px;
                    padding: 1px 8px;
                    border-radius: 2px;
                  " v-for="app in form.viewscreenApps.slice(0, 5)" :key="app.id">
                {{ app.name }}
                <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
              </a-tag>
              <a-tag v-if="form.viewscreenApps.length > 4"> ... </a-tag>
            </div>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <a-modal v-model="addAppsModal" title="Add new apps" :footer="null" width="700px" :destroyOnClose="true">
      <AppsModal @selectApps="changeSelectedApps" @close="closeAddAppsModal" :modalAppsData="modalAppsData" />
    </a-modal>
    <a-modal v-model="addSegmentsModal" title="Add segment" :footer="null" width="450px" :destroyOnClose="true">
      <SegmentsModal @close="closeAddSegmentsModal" @addNewSegment="handleAddSegment" />
    </a-modal>
    <a-modal v-model="editSegmentModal" :footer="null" width="700px" :destroyOnClose="true">
      <template #title>
        <span class="modal-title mr-3">{{ editSegmentTitle }}</span>
        <span class="user-count">{{ editSegmentUserCount }} users</span>
      </template>
      <EditSegmentModal @closeEditSegmentModal="closeEditSegmentModal" @userModal="openAddUserModal"
        :segment-id="form.viewscreenSegment" />
    </a-modal>
    <a-modal v-model="addUserModal" :footer="null" width="450px" :destroyOnClose="true">
      <template #title>
        <span class="modal-title">New user</span>
      </template>
      <UserModal :segment-id="form.viewscreenSegment" @closeUserModal="closeUserModal" @addNewUser="handleAddUser" />
    </a-modal>
    <a-modal v-model="chooseInheritViewscreen" :footer="null" width="400px" :destroyOnClose="true">
      <template #title>
        <span class="modal-title">Choose viewscreen</span>
      </template>
      <ViewscreensModal @cancelModal="cancelViewscreenModal" @viewscreenChosen="changeViewscreenInherit" />
    </a-modal>
  </div>
</template>

<script>
import AppsModal from '@/components/custom/modals/appsModal.vue'
import SegmentsModal from '@/components/custom/modals/segmentsModal.vue'
import EditSegmentModal from '@/views/apps/viewscreens/modals/editSegmentModal.vue'
import UserModal from '@/components/custom/modals/userModal.vue'
import ViewscreensModal from '@/views/apps/viewscreens/modals/viewscreensModal.vue'
import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'viewscreenForm',
  components: { AppsModal, SegmentsModal, EditSegmentModal, UserModal, ViewscreensModal },
  data() {
    return {
      form: {
        viewscreenName: '',
        viewscreenSegment: null,
        viewscreenContent: 'empty',
        startDate: null,
        endDate: null,
        viewscreenApps: [],
        inheritViewscreenId: '',
      },
      inheritViewscreenTitle: '',
      segments: null,
      loading: false,
      addAppsModal: false,
      addUserModal: false,
      chooseInheritViewscreen: false,
      addSegmentsModal: false,
      editSegmentModal: false,
      viewscreenToEdit: null,
      rules: {
        viewscreenName: [
          {
            required: true,
            message: 'Viewscreen name is required',
            trigger: 'blur',
          },
        ],
        viewscreenSegment: [
          {
            required: true,
            message: 'Viewscreen segments are required',
            trigger: 'change',
          },
        ],
        viewscreenContent: [
          {
            required: true,
            message: 'Viewscreen content is required',
            trigger: 'change',
          },
        ],
        viewscreenApps: [
          {
            type: 'array',
            required: true,
            message: 'Applications are required',
            trigger: 'change',
          },
        ],
      },
      modalAppsData: null,
    }
  },
  computed: {
    isEditMode() {
      return !!this.viewscreenToEdit
    },
    viewScreenTitle() {
      return this.isEditMode ? 'Edit viewscreen' : 'New viewscreen'
    },
    contentViewscreen() {
      if (!this.form.inheritViewscreenId) {
        return 'other viewscreen'
      }
      return this.inheritViewscreenTitle
    },
    editSegmentTitle() {
      if (!this.form.viewscreenSegment) {
        return null
      }
      return this.segments.find(segment => segment.id === this.form.viewscreenSegment).name
    },
    editSegmentUserCount() {
      if (!this.form.viewscreenSegment) {
        return null
      }
      return this.segments.find(segment => segment.id === this.form.viewscreenSegment).count
    },
    segmentIsDefault() {
      const defaultId = this.segments.find(segment => segment.is_default)?.id || null
      return this.form.viewscreenSegment === defaultId
    },
    isSegmentEditable() {
      const selectedSegment = this.segments.find(segment => segment.id === this.form.viewscreenSegment)
      return !(this.segmentIsDefault || (selectedSegment && selectedSegment.type !== 'all'))
    },
  },
  async created() {
    await this.getSegments()
    if (this.$route.params.id) {
      await this.getViewscreenTab(this.$route.params.id, '0')
      this.fillForm()
    }
  },
  methods: {
    async save() {
      const isValid = await this.validateForm()
      if (!isValid) {
        return false
      }
      if (!this.isEditMode) {
        return this.createViewscreen()
      }
      this.updateViewscreen()
    },
    fillForm() {
      if (this.isEditMode) {
        this.form.viewscreenName = this.viewscreenToEdit.name
        this.form.viewscreenSegment = this.viewscreenToEdit.segment.id
        if (this.viewscreenToEdit.starts_at.length) {
          this.form.startDate = this.$moment(this.viewscreenToEdit.starts_at).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
        }
        if (this.viewscreenToEdit.ends_in.length) {
          this.form.endDate = this.$moment(this.viewscreenToEdit.ends_in).tz('Etc/GMT-3').format('YYYY-MM-DD HH:mm:ss')
        }
        this.form.viewscreenApps = this.viewscreenToEdit.applications
        this.modalAppsData = this.viewscreenToEdit.applications
      }
    },
    async validateForm() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
      if (!isValid) {
        return false
      }
      return true
    },
    async validateApps() {
      const isValid = await new Promise((resolve) => {
        this.$refs.form.validateField('viewscreenApps', resolve)
      })
      return isValid
    },
    async createViewscreen() {
      try {
        this.loading = true
        const viewscreenPayload = this.getViewscreenPayload()
        const viewscreenResponse = await ViewscreenService.createViewscreenTab(viewscreenPayload)
        if (viewscreenResponse.data.data) {
          this.$notification.success({
            message: 'Viewscreen successfully created',
          })
          this.redirectToMain(viewscreenResponse.data.data.id)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async updateViewscreen() {
      try {
        this.loading = true
        const viewscreenPayload = this.getViewscreenPayload()
        const viewscreenResponse = await ViewscreenService.updateViewscreenTab(this.viewscreenToEdit.id, viewscreenPayload)
        if (viewscreenResponse.data.data) {
          this.$notification.success({
            message: 'Viewscreen successfully updated',
          })
          this.redirectToMain()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async deleteViewscreen() {
      try {
        const response = await ViewscreenService.deleteViewscreenTab(this.viewscreenToEdit.id)
        if (response.data.data) {
          this.$notification.success({
            message: 'Viewscreen successfully deleted',
          })
          this.redirectToMain(null, true)
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
      }
    },
    getViewscreenPayload() {
      const viewscreenPayload = {
        name: this.form.viewscreenName,
        segment_id: this.form.viewscreenSegment,
        applications: this.getApplications(),
      }
      if (this.form.inheritViewscreenId) {
        viewscreenPayload.inherit_id = this.form.inheritViewscreenId
      }
      if (this.form.startDate) {
        viewscreenPayload.starts_at = this.$moment(this.form.startDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.form.endDate) {
        viewscreenPayload.ends_in = this.$moment(this.form.endDate).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
      }
      return viewscreenPayload
    },
    clearForm() {
      this.form = {
        viewscreenName: '',
        viewscreenSegment: null,
        viewscreenContent: 'empty',
        startDate: null,
        endDate: null,
        viewscreenApps: [],
        inheritViewscreenId: '',
      }
      this.modalAppsData = null
    },
    getApplications() {
      return this.form.viewscreenApps.map((app) => {
        return {
          application_id: app.application_id,
          min_app_version: app.min_app_version,
          max_app_version: app.max_app_version,
        }
      })
    },
    changeViewscreenInherit(viewscreenId, selectedViewscreen) {
      this.closeViewscreensModal()

      this.form.inheritViewscreenId = selectedViewscreen ? viewscreenId : ''
      this.inheritViewscreenTitle = selectedViewscreen || ''

      if (!selectedViewscreen) {
        this.form.viewscreenContent = 'empty'
      }
    },
    cancelViewscreenModal () {
      this.form.viewscreenContent = 'empty'
      this.closeViewscreensModal()
    },
    handleAddUser() {
      this.closeUserModal()
      this.openEditSegmentModal()
    },
    handleAddSegment(newSegment) {
      this.closeAddSegmentsModal()
      this.segments.unshift(newSegment)
    },
    // MODALS
    openAddSegmentsModal() {
      if (this.loading) {
        return
      }
      this.addSegmentsModal = true
    },
    closeAddSegmentsModal() {
      this.addSegmentsModal = false
    },
    openEditSegmentModal() {
      if (this.loading) {
        return
      }
      this.editSegmentModal = true
    },
    closeEditSegmentModal() {
      this.editSegmentModal = false
    },
    openAddAppModal() {
      if (this.loading) {
        return
      }
      this.addAppsModal = true
    },
    closeAddAppsModal() {
      this.addAppsModal = false
    },
    openAddUserModal() {
      this.closeEditSegmentModal()
      this.addUserModal = true
    },
    closeUserModal() {
      this.addUserModal = false
    },
    openViewscreensModal() {
      this.chooseInheritViewscreen = true
    },
    closeViewscreensModal() {
      this.chooseInheritViewscreen = false
    },
    // MODALS //
    changeSelectedApps(apps) {
      this.closeAddAppsModal()
      this.form.viewscreenApps = apps
      this.modalAppsData = apps
      this.validateApps()
    },
    checkContentType(event) {
      if (event.target.value === 'empty') {
        this.form.inheritViewscreenId = ''
        this.inheritViewscreenTitle = ''
        return
      }
      this.openViewscreensModal()
    },
    async getSegments() {
      this.loading = true
      try {
        const response = await ViewscreenService.getSegments(undefined, undefined, 1000)
        if (response.data.data) {
          this.segments = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
    async getViewscreenTab(id, langId) {
      this.loading = true
      try {
        const response = await ViewscreenService.getViewscreenTab(id, langId)
        if (response.data.data) {
          this.viewscreenToEdit = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
    redirectToMain(createdId, isDeleted) {
      if (isDeleted) {
        return this.$router.push('/main')
      }
      if (this.isEditMode) {
        return this.$router.push(`/main/${this.$route.params.id}`)
      }
      this.$router.push(`/main/${createdId}`)
    },
  },
}
</script>

<style lang="scss">
.user-count {
  color: #8C8C8C;
  font-weight: 400;
  font-size: 14px;
}

.viewscreen-form {
  gap: 1rem;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
  &__content {
    label {
      margin-bottom: 0;
    }
  }
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
}

.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222e;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
</style>
